// ** React Imports
import { memo } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Block from "components/layout/Block";
import Section from "components/layout/Section";
import DecorBox from "components/layout/DecorBox";
import MilionNumberPresenter from "components/other/MilionNumberPresenter";

// ** Utils & Helpers
import pseudoFormat from "utils/pseudoFormat";

// #####################################################

const StatisticsSection = () => {
	const { t } = useTranslation(["common", "landing"]);

	const usersOnline = useSelector((state) => state.global.statistics.online);
	const mediaTotal = useSelector(
		(state) => state.global.statisticsMain.media
	);
	const registeredUsers = useSelector(
		(state) => state.global.statisticsMain.users
	);

	const isDarkMode = useSelector((state) => state.global.darkMode.darkMode);

	// #####################################################

	return (
		<Section>
			<Block flex flexWrap>
				<DecorBox
					topText={t("siteStats.currently")}
					mainText={pseudoFormat(usersOnline)}
					bottomText={t("siteStats.usersOnline")}
				/>
				<DecorBox
					topText={t("siteStats.moreThan")}
					mainText={<MilionNumberPresenter value={registeredUsers} />}
					bottomText={t("siteStats.registeredUsers")}
				/>
				<DecorBox
					topText={t("siteStats.moreThan")}
					mainText={<MilionNumberPresenter value={mediaTotal} />}
					bottomText={t("siteStats.addedMedia")}
				/>
			</Block>
		</Section>
	);
};

// #####################################################

export default memo(StatisticsSection);

// #####################################################
