import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Corner = styled("div")`
	position: absolute;
	width: 20px;
	height: 20px;

	/* ${({ zoomEffect }) =>
		zoomEffect &&
		css`
			transition: 100ms all;
		`} */

	${({ left }) =>
		left &&
		css`
			left: 0;
			border-left: ${({ theme: { colors } }) =>
				`1px solid ${colors.black}`};
		`}
	${({ right }) =>
		right &&
		css`
			right: 0;
			border-right: ${({ theme: { colors } }) =>
				`1px solid ${colors.black}`};
		`}
	${({ top }) =>
		top &&
		css`
			top: 0;
			border-top: ${({ theme: { colors } }) =>
				`1px solid ${colors.black}`};
		`}
	${({ bottom }) =>
		bottom &&
		css`
			bottom: 0;
			border-bottom: ${({ theme: { colors } }) =>
				`1px solid ${colors.black}`};
		`}
`;

const Box = styled("div")`
	flex: 1;
	position: relative;
	padding: ${({ small }) => (small ? 0 : "1rem")};
	text-align: center;
	margin: 0.5rem;
`;

const TopText = styled("p")`
	font-size: ${({ small }) => (small ? "0.8rem" : "1.2rem")};
	line-height: 1.5rem;
`;

const MainText = styled("p")`
	font-size: ${({ small }) => (small ? "1.5rem" : "2.3rem")};
	font-weight: bold;
	white-space: nowrap;
`;

const BottomText = styled("p")`
	font-size: ${({ small }) => (small ? "0.8rem" : "1.2rem")};
	line-height: ${({ small }) => (small ? "1rem" : "1.5rem")};
`;

const DecorBox = ({ topText, mainText, bottomText, small }) => (
	<Box>
		{!small ? (
			<>
				<Corner left top />
				<Corner right top />
				<Corner left bottom />
				<Corner right bottom />
			</>
		) : null}
		<TopText small={small}>{topText}</TopText>
		<MainText small={small}>{mainText}</MainText>
		<BottomText small={small}>{bottomText}</BottomText>
	</Box>
);

DecorBox.propTypes = {
	topText: PropTypes.string.isRequired,
	mainText: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.node,
	]).isRequired,
	bottomText: PropTypes.string.isRequired,
	small: PropTypes.bool,
};
DecorBox.defaultProps = {
	small: false,
};

export default DecorBox;
